$primary_color : rgb(58, 86, 150);
$secondary_color : rgb(235, 244, 249);

$hover_color : #1e91c8;

@import url("//hello.myfonts.net/count/3499db");
  
@font-face {
    font-family: 'AccordAlternate';
    src: url('../../fonts/accord/3499DB_0_0.eot');
    src: url('../../fonts/accord/3499DB_0_0.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/accord/3499DB_0_0.woff2') format('woff2'),
         url('../../fonts/accord/3499DB_0_0.woff') format('woff'),
         url('../../fonts/accord/3499DB_0_0.ttf') format('truetype');
    font-weight: 300;
}
 
  
@font-face {
    font-family: 'AccordAlternate';
    src: url('../../fonts/accord/3499DB_1_0.eot');
    src: url('../../fonts/accord/3499DB_1_0.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/accord/3499DB_1_0.woff2') format('woff2'),
         url('../../fonts/accord/3499DB_1_0.woff') format('woff'),
         url('../../fonts/accord/3499DB_1_0.ttf') format('truetype');
    font-weight: 400;
}
 
  
@font-face {
    font-family: 'AccordAlternate';
    src: url('../../fonts/accord/3499DB_2_0.eot');
    src: url('../../fonts/accord/3499DB_2_0.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/accord/3499DB_2_0.woff2') format('woff2'),
         url('../../fonts/accord/3499DB_2_0.woff') format('woff'),
         url('../../fonts/accord/3499DB_2_0.ttf') format('truetype');
    font-weight: 700;
}

body {
    font-family: 'AccordAlternate', 'Helvetica', sans-serif;
    font-weight: 300;
    font-size: 18px;
    margin: 15px;
    margin-top: 1.5em;
}

#logo {
    width: 240px;
    margin-left: 15px;
}

h1 {
    color: $primary_color;
    font-weight: 400;
    margin-left: 15px;
    font-weight: 400;
}

h2 {
    font-weight: 400;
    margin-left: 15px;
    font-weight: 400;
}

p {
    line-height: 145%;
}

input, textarea, select {
    &:focus {
        outline: 0 none;
    }
}

main {
    margin: 0 auto;
    max-width: 500px;
    &>p {
        padding: 0 15px;
    }
    
    a {
        color: $primary_color;
        font-weight: 700;
    }
}

.go-form {
    background-color: $secondary_color;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    fieldset {
        border: 0 none;
        padding: 0;
        input {
            border: 0 none;
            width: 100%;
            padding: 0 6px 0 15px;
            height: 45px;
            font: inherit;
            box-sizing: border-box;
        }
    }
    &__label {
        display: block;
        &__text {
            color: rgb(116, 116, 116);
            margin-bottom: .5em;
        }
        &+.go-form__label {
            margin-top: 1em;
        }
    }
    &__button {
        border: 0 none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        cursor: pointer;
        font-size: 22px;
        font-weight: 700;
        padding: 0 40px 0 20px;
        color: #fff;
        background-color: $primary_color;
        height: 45px;
        &:hover {
            color: #ffffff;
            background-color: $hover_color;
        }
        &--proceed {
            &:after {
                content: "";
                position: absolute;
                margin: 7px 0 0 10px;
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-left: 12px solid #ffffff;
                border-bottom: 6px solid transparent;                
            }
            
        }
    }    
    &__file {
        &__progress {
            height: 20px;
            width: 100%;
            background-color: #fff;
            &>div {
                height: 100%;
                width: 0;
                transition: width .5s ease;
                background-color: $hover_color;
            }
        }
        &__container {
            background-color: $primary_color;
            overflow: hidden;
            position: relative;
            &__label {
                color: #fff;
                font-weight: 700;
                text-align: center;
                padding: 0 40px 0 20px;
                height: 45px; 
                display: block;
                vertical-align: middle;
                line-height: 45px;
            }
            input {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0;
                opacity: 0;
                font-size: 200px !important;
                direction: ltr;
                cursor: pointer;      
                &[type="text"] {
                    z-index: -1;
                }
            }
            [name] {
                &.invalid {
                    border: 1px solid orangered;
                }
            }
        }
        &__messages {
            &>div {
                padding-top: 1em;
            }
            &__error {
                color: orangered;
            }
            &__success {
                color: $hover_color;
            }            
        }
    }
    &__feedback {
        &__success {
            color: $hover_color;
        }
        &__error {
            color: orangered;
        }
    }
    fieldset>* {
        &+.go-form__feedback {
            &>* {
                padding-top: 1em;
            }            
        }
    }
}